<nz-breadcrumb
  [nzAutoGenerate]="true"
  [nzSeparator]="iconTemplate"
  [nzRouteLabel]="'title'"
  [nzRouteLabelFn]="translateFn"
>
  <nz-breadcrumb-item></nz-breadcrumb-item>
</nz-breadcrumb>

<ng-template #iconTemplate>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.39727 4.99978L3.54102 3.14353L4.07127 2.61328L6.45777 4.99978L4.07127 7.38628L3.54102 6.85603L5.39727 4.99978Z"
      fill="#718198"
    />
  </svg>
</ng-template>
