import { Component } from '@angular/core';
import {
  NzBreadCrumbComponent,
  NzBreadCrumbItemComponent,
} from 'ng-zorro-antd/breadcrumb';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ie-breadcrumbs',
  standalone: true,
  imports: [NzBreadCrumbComponent, NzBreadCrumbItemComponent],
  templateUrl: './ie-breadcrumbs.component.html',
  styleUrl: './ie-breadcrumbs.component.scss',
})
export class IeBreadcrumbsComponent {
  constructor(private translateService: TranslateService) {}

  translateFn = (key: string) => {
    return this.translateService.instant(key || 'PAGE_TITLE.DEFAULT');
  };
}
